@import '../../../styles/_variables.scss';

.logo_title {
  flex: 1;
  padding-left: 12px;
  font-family: Lab Grotesque;
}

.small_title {
  padding-left: 5px;
  font-size: 12px;
}

.item-list {
  padding: 5px 12px 5px 12px;
  display: flex;
  align-items: center;
  background-color: #fcfcfc;
  font-weight: 300;
  color: #454545;

  &:hover {
    background-color: #f0f0f0;
    font-weight: 500;
  }
}

.selectedItem {
  background-color: #f0f0f0;
  font-weight: 500;
}

.title {
  font-size: 9px;
  padding: 5px 12px;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #999999;
}
