@import '../../../styles/_variables.scss';

.settings-container {
  height: 100vh;
  max-height: 400px;
  display: flex;
  margin: 8px;
}

.settings-categories,
.settings-content {
  overflow: auto;
  height: 100%;
}

.settings-content {
  margin-left: 5px;
}

.settings-update {
  border-top: 1px solid $light-gray-1;
  background-color: $white;
  padding: 12px 26px;
  display: flex;
  flex-direction: row;
  @include gap(8px);
  position: fixed;
  bottom: 8px;
  width: 350px;
  box-sizing: border-box;
  z-index: 1500;
}

.team-member {
  padding-right: 0;
  padding-left: 0;
  // Disable pointer events until we add ability to change users
  pointer-events: none;
}

.settings-content--inner {
  margin-bottom: 54px;
}
