@import '../../../styles/_variables.scss';

.card {
  @include shadow-menu;
  border-radius: 2px;
  background: $white;
  box-sizing: border-box;

  &--transparent {
    background: transparent;
  }

  &--shadowless {
    box-shadow: none;
  }
}

.card-body {
  box-sizing: border-box;
  &--form {
    padding: 18px 26px;
    display: flex;
    flex-direction: column;
    & > * + * {
      margin-top: 12px;
    }
    .divider {
      height: 12px;
    }
  }

  &--list {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  // Sizes
  &--xl {
    width: 350px;
  }

  &--large {
    width: 300px;
  }

  &--medium {
    width: 250px;
  }

  &--small {
    width: 200px;
  }

  &--tiny {
    width: 175px;
  }
}
