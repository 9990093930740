@import '../../../styles/_variables.scss';

.input-error {
  color: $light-gray-3;
  background-color: $dark-gray-4;
  position: absolute;
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: flex-start;
  padding: 0 3px;
  height: 18px;
  border-radius: 2px;
  white-space: nowrap;
  @include gap(3px);
  @include shadow-toast;
  @include small-text;

  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  button,
  img,
  .icon {
    flex-grow: 0;
  }

  p,
  a,
  span {
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
