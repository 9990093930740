@import '../../../styles/_variables.scss';
.button-wrapper {
  display: flex;
}

.button {
  @include fade-bg;
  @include p;
  line-height: rem(11px);

  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  text-decoration: none;
  white-space: nowrap;

  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;

  button,
  img,
  .icon {
    flex-grow: 0;
  }

  p,
  a,
  span {
    flex: 1;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.75;
  }

  // Colors
  //////////////////////////////////////////////////////////////////////////////
  &--white {
    background: $white;
    color: $dark-gray-2;
    border: 1px solid $light-gray-2;

    &:hover {
      background: $light-gray-1;
    }

    &:active {
      background: $light-gray-2;
    }
  }

  &--gray-1 {
    background: $light-gray-1;
    border: 1px solid $light-gray-3;
    color: $dark-gray-2;

    &:hover {
      background: $light-gray-2;
    }

    &:active {
      background: $light-gray-3;
    }
  }

  &--gray-2 {
    background: $light-gray-2;
    border: 1px solid $light-gray-4;
    color: $dark-gray-2;

    &:hover {
      background: $light-gray-3;
    }

    &:active {
      background: $light-gray-4;
    }
  }

  &--tool-active {
    background: $light-gray-2;
    color: $dark-gray-2;
  }

  &--blue {
    color: $white;
    background: $light-blue;
    border: 1px solid $blue;

    &:hover {
      background: $mid-blue;
    }

    &:active {
      background: $blue;
    }
  }

  &--floating {
    @include shadow-toast;
  }

  &--borderless {
    border: 0;
  }

  // Size
  //////////////////////////////////////////////////////////////////////////////
  &--full {
    width: 100%;
  }

  &--large {
    @include h3;
    height: 40px;
    padding: 0 12px;
    @include gap(12px);
  }

  &--medium {
    height: 28px;
    padding: 0 8px;
    @include gap(5px);
  }

  &--small {
    height: 22px;
    padding: 0 5px;
    @include gap(5px);
  }

  &--tiny {
    height: 16px;
    padding: 0 2px;
    @include gap(2px);
  }

  &--circle {
    border-radius: 50%;
    padding: 8px;
  }

  > img,
  > span {
    // https://stackoverflow.com/a/7196517
    // remove default spacing underneath
    vertical-align: middle;
  }
}
