@import '../../../styles/_variables.scss';

.avatars {
  position: fixed;
  top: 8px;
  right: 74px;
  z-index: 800;
}

.avatar {
  position: relative;
  display: inline-block;
  height: 22px;
  width: 22px;
  margin-left: -3px;

  &:hover {
    .avatar__name {
      display: block;
    }
  }

  img {
    height: 100%;
    width: 100%;
    border-radius: 100%;
    @include shadow-toast;
  }

  &__name {
    display: none;
    text-align: center;
    position: absolute;
    top: 27px;
    left: 50%;
    transform: translateX(-50%);
  }
}
