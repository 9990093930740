@import '../../../styles/_variables.scss';
// Sizes
.image-input {
  @include fade-bg;
  box-sizing: border-box;
  border-radius: 2px;
  overflow: hidden;

  &--normal {
    height: 48px;
    width: 48px;
    &:hover {
      background-color: $light-gray-2;
    }
    &:active {
      background-color: $light-gray-3;
    }
    .image-input__button--default {
      border: 1px solid $light-gray-3;
    }
    .image-input__preview--default {
      width: 26px;
      height: 26px;
    }
  }

  &--small {
    height: 28px;
    width: 28px;
    @include fade-bg;
    &:hover {
      background-color: $light-gray-1;
    }
    &:active {
      background-color: $light-gray-2;
    }
    .image-input__button--default {
      border: 1px solid $light-gray-2;
    }
    .image-input__preview--default {
      width: 20px;
      height: 20px;
    }
  }
}

// Button
.image-input__button--default,
.image-input__button--custom {
  position: relative;
  width: 100%;
  height: 100%;
}

.image-input__preview--default,
.image-input__preview--custom {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  object-fit: cover;
}

// Input
.image-input__input {
  display: none;
}

.image-input__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: rgba($black, 0);
  transition: opacity 0.2s ease;
}

// Custom
.image-input__button--custom {
  border: none;

  &:hover {
    .image-input__overlay {
      background: rgba($black, 0.5);
      opacity: 1;
    }
  }

  &:active {
    .image-input__overlay {
      background: rgba($black, 0.75);
    }
  }
}
