.onboarding-layout-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.onboarding-layout-logo {
  width: 36px;
  height: auto;
  margin-bottom: 26px;
}

.onboarding-layout-header {
  margin-top: 78px;
}

.onboarding-layout-subheading {
  margin-top: 16px;
}

.onboarding-layout-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .onboarding-layout-buttons,
  .onboarding-layout-hero {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > * + * {
      margin-top: 8px;
    }
  }

  .onboarding-layout-hero {
    flex: 1;
    justify-content: center;

    .install-support {
      width: auto;
    }

    input,
    button {
      width: 250px;
    }

    .input-button {
      input,
      button {
        width: 100%;
      }
    }
  }

  .onboarding-layout-buttons {
    flex-grow: 0;
    margin-top: 26px;
  }
}

.onboarding-layout-footer {
  flex: 0;
  margin-top: 26px;
  padding-bottom: 78px;
  max-width: 350px;
}
