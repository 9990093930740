@import '../../styles/_variables.scss';

.onboarding--teammate-email {
  color: $dark-gray-2;
  height: 40px;
  padding: 0 12px;
  h3 {
    margin: 0 !important;
  }
  &--close {
    @include fade;
    opacity: 0;
  }
  &:hover {
    background-color: transparent;
    .onboarding--teammate-email--close {
      opacity: 1;
    }
  }
  &:active {
    background-color: transparent !important;
  }
}

.teammate-email-input input {
  min-width: 175px;
}

.invite-team--hero {
  width: 300px;
  flex: 1;
  margin: 26px 0;

  & > * + * {
    margin-top: 8px;
  }

  .divider {
    margin: 0;
  }

  .teammate--list {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    h3 {
      margin-top: 12px;
    }
  }
}
