@import '../../../styles/variables.scss';

.canvas-input {
  @include h1;
  font-weight: 500;
  background-color: transparent;
  outline: none;
  border: none;
  color: $dark-gray-2;
  text-overflow: ellipsis;
  max-width: 450px;

  &::placeholder {
    color: $light-gray-3;
    opacity: 1; /* Firefox */
  }
}
