@import '../../../styles/_variables.scss';

.topMenu {
  position: fixed;
  z-index: 900;
  top: 8px;
  left: 8px;
  height: 22px;
  display: flex;
  align-items: center;
}

.breadcrumb {
  margin-left: 5px;
  color: $dark-gray-1;
  cursor: default;
  &:hover,
  :active {
    background-color: $white;
  }
}
