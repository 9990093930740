.square-logo {
  border-radius: 2px;
  overflow: hidden;
  object-fit: cover;

  &--large {
    width: 36px;
    height: 36px;
  }

  &--medium {
    width: 26px;
    height: 26px;
  }

  &--small {
    width: 12px;
    height: 12px;
  }
}
