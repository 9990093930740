@import '../../../styles/_variables.scss';

.sidebar {
  position: fixed;
  width: 200px;
  overflow-y: auto;
  left: -200px;
  top: 38px;
  bottom: 38px;
  background: $white;
  @include shadow-menu;
  border-radius: 0px 2px 2px 0px;
  z-index: 900;
  transition: left 0.17s ease-in-out;
  display: flex;
  flex-direction: column;

  .settings-button--wrapper {
    position: fixed;
    bottom: 38px;

    .settings-button {
      border-radius: 0px 0px 2px 0px;
      width: 200px;
      background-color: $white;
    }
  }

  .team-select--wrapper {
    position: fixed;
    top: 38px;

    .team-select {
      border-radius: 0px 2px 0px 0px;
      width: 200px;
      background-color: $white;
      &:hover {
        .expandMore {
          opacity: 1;
        }
      }
    }
  }

  &--open {
    left: 0px;
  }
}

.sidebar-add-button,
.expandMore {
  @include fade;
  opacity: 0;
}

.sidebar-canvases:hover {
  .sidebar-add-button {
    opacity: 1;
  }
}

.sidebar-projects:hover {
  .sidebar-add-button {
    opacity: 1;
  }
}

.team-select--popover {
  .description {
    color: $dark-gray-1;
    margin-top: 2px;
  }

  .log-out {
    color: $dark-gray-1;
  }
}

// Add margins to account for fixed header & footer
.sidebar-projects {
  margin-top: 50px;
  margin-bottom: 26px;
}

.sidebar-canvases {
  margin-bottom: 36px;
}
