@import '../../styles/_variables.scss';

.onboarding-video {
  max-width: 725px;
  width: 100%;
  margin-bottom: 4px;
}

.install-support {
  &:active {
    background-color: $light-gray-2;
  }
}

.line-break {
  height: 5px;
}
