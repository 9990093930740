@import '../../../styles/_variables.scss';

.helperWrapper {
  position: fixed;
  right: 18px;
  bottom: 18px;
  z-index: 900;
}

.helper--link {
  text-decoration: none;
}

.helper--social-links {
  height: 26px;
}

.helper--social-icon-2 {
  margin-left: 3px;
}

.helper--version-number {
  height: 17px;
  padding: 0 $list-x-padding 5px $list-x-padding;
}

.helper-button-container {
  float: right;
}
