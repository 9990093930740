@import '../../../styles/_variables.scss';

.syncing-wrapper {
  display: flex;
  opacity: 0;
  position: fixed;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 900;
  color: $dark-gray-1;
  pointer-events: none;
  @include fade;
  @include shadow-toast;
  &.syncing {
    opacity: 1;
  }
}
