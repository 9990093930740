.unstyled-button {
  &--full {
    width: 100%;
  }

  &--text-align-left {
    text-align: left;
  }

  &--display-block {
    display: block;
  }

  &--no-hover {
    cursor: default !important;
  }
}
