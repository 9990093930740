@import '../../../styles/_variables.scss';

.tooltip {
  color: $light-gray-3;
  background-color: $dark-gray-4;
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: flex-start;
  padding: 5px;
  min-height: 22px;
  max-width: 150px;
  border-radius: 2px;
  @include gap(5px);
  @include shadow-toast;
  @include p;
  @include fade-in;

  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  button,
  img,
  .icon {
    flex-grow: 0;
  }

  p,
  a,
  span {
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ul {
    @include gap-vertical(5px);
    li {
      display: flex;
      align-items: center;
      p {
        line-height: 11px;
      }
      .icon {
        margin-right: 3px;
      }
    }
  }
}
