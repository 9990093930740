@import '../../../styles/_variables.scss';

.tools {
  position: fixed;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  display: block;
  z-index: 800;

  button {
    display: block;
    &:first-of-type {
      margin-bottom: 5px;
    }
  }
}

.tool__wrapper {
  position: relative;
  &:hover {
    .tip {
      display: block;
    }
  }
  .tip {
    display: none;
    position: absolute;
    left: 27px;
    top: 0px;
  }
}
