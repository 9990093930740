@import '../../../styles/_variables.scss';

.sidebar-item {
  // hide icons buttons unless hovering
  &:hover {
    .sidebar-item__options-button {
      visibility: visible;
      &:not(:hover) {
        background-color: transparent;
      }
    }
  }

  &__options-button {
    visibility: hidden;
    margin-right: -2px;
  }

  &__link {
    text-decoration: none;
  }

  &__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__rename-form {
    flex: 1;
    input {
      box-sizing: border-box;
      height: 18px !important;
      margin: 2px 0 2px -3px;
      padding: 0 2px;
    }
  }
}
