@import '../../../styles/_variables.scss';

.input {
  @include fade;
  background: $white;
  box-shadow: none;
  color: $dark-gray-3;
  box-sizing: border-box;
  border-radius: 2px;
  border: 1px solid $light-gray-2;
  width: 100%;

  &:hover {
    border-color: $light-gray-3;
  }

  &:focus {
    border-color: $light-gray-4;
  }

  &::placeholder {
    color: $dark-gray-1;
  }

  &:disabled {
    color: $dark-gray-1;
    cursor: not-allowed;

    &:hover,
    &:focus {
      border-color: $light-gray-1;
    }
  }

  &--large {
    @include h3;
    padding: 0 16px;
    height: 48px;
  }

  &--medium {
    @include p;
    padding: 0 8px;
    height: 28px;
  }

  &--full {
    width: 100%;
  }
}
