@import '../../../styles/_variables.scss';

.list-label {
  @include small-text;
  @include gap(5px);
  line-height: rem(11px);
  height: 22px;
  color: $dark-gray-1;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 $list-x-padding;

  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  button,
  img,
  .icon {
    flex-grow: 0;
  }

  p,
  a,
  span {
    flex: 1;
  }
}
