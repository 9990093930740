@import './styles/variables';

* {
  font-family: 'Lab Grotesque', 'Helvetica', sans-serif;
  font-style: normal;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  // font-family: 'Lab Grotesque', 'Helvetica', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $light-gray-1;
}

// TODO - was added as part of left menu, but caused other problems, so remove if determined not needed
// * {
//   box-sizing: border-box;
// }

body,
html,
#root {
  height: 100%;
  color: $dark-gray-1;
  // TODO - was added as part of left menu, but caused other problems, so remove if determined not needed
  // box-sizing: border-box;
}

a {
  color: inherit;
}

li,
ul,
ul li,
ul ul li {
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

// Buttons & Inputs
////////////////////////////////////////////////////////////////////////////////

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  cursor: pointer;

  &:focus,
  &:active {
    outline: none;
  }
}

input {
  &:focus,
  &:active {
    outline: none;
  }
}

// TODO: not sure why this is set, commenting out
// img {
//   width: 100%;
// }

input::placeholder {
  color: #f0f0f0;
}
/* Helper classes for media queries */
/* @media only screen and (min-width: 721px) {
  .show-on-mobile {
    display: none !important;
  }
}

@media only screen and (max-width: 720px) {
  .hide-on-mobile {
    display: none !important;
  }
} */

.input-button {
  display: flex;
  width: 100%;
  @include gap(5px);
  > div {
    position: relative;
  }
  input {
    flex: 1;
  }
  button {
    flex: 0;
  }
}

// File Drop
////////////////////////////////////////////////////////////////////////////////

.file-drop {
  /* relatively position the container bc the contents are absolute */
  position: relative;
  height: 100%;
  width: 100%;
}

.file-drop > .file-drop-target {
  /* basic styles */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 2px;

  /* horizontally and vertically center all content */
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;

  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;

  align-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;

  justify-content: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;

  align-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;

  text-align: center;
}

.file-drop > .file-drop-target.file-drop-dragging-over-frame {
  /* overlay a black mask when dragging over the frame */
  border: none;
  // background-color: rgba(0, 0, 0, 0.65);
  box-shadow: none;
  z-index: 50;
  opacity: 1;

  /* typography */
  color: white;
}

.file-drop > .file-drop-target.file-drop-dragging-over-target {
  /* turn stuff orange when we are dragging over the target */
  color: #ff6e40;
  box-shadow: 0 0 13px 3px #ff6e40;
}

// Tooltips
////////////////////////////////////////////////////////////////////////////////

.tooltip--delayed {
  @include fade-in;
  animation-delay: 0.6s !important;
}

// Popover
////////////////////////////////////////////////////////////////////////////////

// override to make sure popovers appear way on top
.react-tiny-popover-container {
  z-index: 1500;
}

// if we have nested popovers, make second one lower
.react-tiny-popover-container + .react-tiny-popover-container {
  z-index: 1400;
}

// Toasts
////////////////////////////////////////////////////////////////////////////////

.Toastify {
  .Toastify__toast-container {
    padding: 0;
    bottom: 16px;
    display: flex;
    justify-content: center;

    // Classes for the displayed toast
    .Toastify__toast {
      position: absolute;
      bottom: 0;
      margin: 0;
      min-height: 28px;
      max-width: 350px;
      overflow: visible;
      padding: 8px;
      color: $dark-gray-2;
      background-color: $white;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-align: left;
      border-radius: 2px;
      text-decoration: none;

      @include gap(5px);
      @include shadow-toast;
      @include p;
      @include fade-bg;

      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;

      -webkit-font-smoothing: inherit;
      -moz-osx-font-smoothing: inherit;
      -webkit-appearance: none;
    }

    .Toastify__toast-body {
      margin: 0;
      padding: 0;
      width: 100%;
      overflow-wrap: break-word;
      word-wrap: break-word;
    }

    .Toastify__toast--success {
    }

    .Toastify__toast--error {
      color: $red;
    }
    .Toastify__progress-bar {
      visibility: hidden;
    }
  }
}

// Toast Animation
.toast-in {
  animation: toast-in 0.15s ease-out both;
  animation-duration: 0.15s !important;
  @keyframes toast-in {
    0% {
      transform: translateY(50px);
    }
    100% {
      transform: translateY(0);
    }
  }
}

.toast-out {
  animation: toast-out 0.09s ease-in-out both;
  animation-duration: 0.09s !important;

  @keyframes toast-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
