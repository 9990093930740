// Prevent canvas zoom + scroll actions via overlay
.new-canvas-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  &--unlocked {
    pointer-events: none;
  }

  .new-canvas-menu {
    pointer-events: all;
    position: fixed;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 90%;
    max-width: 300px;
    top: 50%;
  }

  .new-canvas-menu--text {
    margin: 0 12px;
    padding: 0;
  }
}
