@import '../../../styles/_variables.scss';

.list-item {
  @include fade-bg;
  color: $dark-gray-3;
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: flex-start;
  padding: 0 $list-x-padding;

  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  button,
  img,
  .icon {
    flex-grow: 0;
  }

  h3,
  p,
  a,
  span {
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  p,
  .small-text {
    line-height: rem(11px);
  }

  &:hover:not(.list-item--disabled) {
    background: $light-gray-1;
  }

  &:active:not(.list-item--disabled) {
    background-color: $light-gray-2;
  }

  &--selected {
    background: $light-gray-1;

    &:hover:not(.list-item--no-hover) {
      background: $light-gray-2;
    }

    &:active:not(.list-item--no-hover) {
      background-color: $light-gray-3;
    }
  }

  &--disabled {
    color: $dark-gray-1;
    cursor: default !important;
  }

  &--large {
    height: 60px;
    @include gap(12px);
  }

  &--team-select {
    height: 50px;
    @include gap(12px);
  }

  &--settings {
    height: 36px;
    @include gap(8px);
  }

  &--medium {
    height: 28px;
    @include gap(5px);
  }

  &--small {
    height: 22px;
    @include gap(5px);
    a,
    p,
    span {
      min-width: 0;
    }
  }
}
