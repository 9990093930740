@import '../../../styles/_variables.scss';

.modal-content {
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  outline: none;
}
