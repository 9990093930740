@import '../../../styles/_variables.scss';

.menuItem-heading {
  display: flex;
  height: 16px;

  h3 {
    flex: 0;
    line-height: rem(17px);
  }

  .help-tip {
    flex: 0;
    float: none;
    margin-left: 5px;
  }
}

.description {
  color: $dark-gray-1;
  margin-top: 3px;
}
