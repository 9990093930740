@import '../../styles/_variables.scss';

.noteEditorWrapper {
  position: absolute;
  z-index: 2;
}

.noteEditorFontSizeRow {
  display: flex;
  position: absolute;
  top: -27px;
}

.noteEditorFontSelector {
  cursor: pointer;
  margin-right: 3px;
  width: 22px;
  height: 22px;
  border-radius: 2px;
  background-color: $white;
  &:hover {
    background-color: $light-gray-1;
  }
  &:active {
    background-color: $light-gray-2;
  }
}

.noteEditorFontSelectorIcon {
  padding: 5px;
  width: 12px;
  height: 12px;
}

.fontSelectorActive {
  background-color: $light-gray-2;
  &:hover {
    background-color: $light-gray-2;
  }
}

.noteTextInput {
  resize: none;
  background-color: transparent;
  height: 16px; // set height to smallest size by default (h3 line height)
  outline: none;
  border: none;
  overflow: hidden;
  transform-origin: top left;
}

.fontSizeH3 {
  @include h3;
}

.fontSizeH2 {
  @include h2;
}

.fontSizeH1 {
  font-size: rem(60px);
  line-height: rem(62px);
  font-weight: 500;
  letter-spacing: normal;
}
