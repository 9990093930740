@import '../../../styles/_variables.scss';

.add-button {
  position: fixed;
  top: 8px;
  right: 8px;
  z-index: 800;
}

.add__container {
  position: fixed;
  top: 35px;
  right: 8px;
  z-index: 800;
}
