@import '../../../styles/_variables.scss';

.card {
  .list-item:hover {
    cursor: pointer;
  }
}

.add-menu--popover {
  margin-left: -12px;
  .input-button {
    padding: 8px;
  }
}
