@import '../../../styles/_variables.scss';

.divider {
  width: 100%;
  height: 6px;
  display: flex;
  align-items: center;

  hr {
    width: 100%;
    background-color: $light-gray-1;
    height: 1px;
    border: none;
  }

  &--dark hr {
    background-color: $light-gray-2;
  }
}
